import { EnvironmentService } from '@services/root/environment.service';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { IEnvironment } from '@environments/IEnvironment';
import { DevSettings } from '@common/models/developer-settings/developer-settings';

declare global {
  interface Window {
    envConf: IEnvironment
  }
}

const service = new EnvironmentService(
  new HttpClient(
    new HttpXhrBackend({ build: () => new XMLHttpRequest })
  )
);
const devSettings = new DevSettings();
export default () => service.getEnvironment(devSettings.env);
