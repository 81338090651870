import { IEnvironment } from '@environments/IEnvironment';
import { DevSettings } from '@common/models/developer-settings/developer-settings';
const devSettings = new DevSettings();

/**
 * Allow overriding baseUrls with apiOrigin from dev-settings for debugging purposes
 *
 * @param environment Environment file
 */
export default (environment: IEnvironment): IEnvironment => {
  let env: IEnvironment = environment;
  const apiOrigin = devSettings.apiOrigin;

  if (apiOrigin) {
    const formattedOrigin = apiOrigin.endsWith('/') ? apiOrigin : apiOrigin + '/';
    const envCopy: IEnvironment = JSON.parse(JSON.stringify(env));
    envCopy.api.baseUrl = formattedOrigin;
    env = envCopy;
  }

  return env;
}
